import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import UpgradeGuide from '@workday/canvas-kit-docs/dist/mdx/10.0-UPGRADE-GUIDE.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const ExternalContent = makeShortcode("ExternalContent");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/* Update name of file in CK to be 10.0-UPGRADE-GUIDE.mdx */}

    <TabPanel data-id="Overview" mdxType="TabPanel">
      <ExternalContent mdxType="ExternalContent">
        <h3 {...{
          "id": "upgrade-overview"
        }}>{`Upgrade Overview`}</h3>
        <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v10 release. The impact for developers are defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Token Structure & Naming Convention`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new token structure and updated token naming convention will be rolled out`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Opacity System Token`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new opacity token will be available for use with disabled states`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Styling Strategy for Buttons`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new styling strategy will be rolled out to Canvas buttons in order to make buttons compatible with CSS variables while moving away from Emotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Token Rollout to Canvas Buttons`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new token set`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Space & Depth Rem Conversion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space and Depth tokens will be converted from pixel to rem`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of spaceNumbers`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`In favor of moving to rems, `}<inlineCode parentName="td">{`spaceNumbers`}</inlineCode>{` will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select Compound Component Conversion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Select compound component will replace the current Select component in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of Select in Preview`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Select component in Preview will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode>{` utility will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of Table in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Table component in Main will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
      </ExternalContent>
      <InternalContent mdxType="InternalContent">
        <h3 {...{
          "id": "upgrade-overview-1"
        }}>{`Upgrade Overview`}</h3>
        <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v10 release. The impact for designers and developers are defined as follows:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9 and/or switch to the new
v10 Canvas Web Figma library`}</li>
          <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
          <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
          <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Change`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Short Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Designer Impact`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Developer Impact`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Token Structure & Naming Convention`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new token structure and updated token naming convention will be rolled out`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Space and Shape Figma Variables`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space and Shape tokens will be available for designers to utilize as pre-set Figma variables`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Opacity System Token`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new opacity token will be available for use with disabled states`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`New Styling Strategy for Buttons`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new styling strategy will be rolled out to Canvas buttons in order to make buttons compatible with CSS variables while moving away from Emotion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Token Rollout to Canvas Buttons`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new token set`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Tertiary Inverse Button Styling Standardization`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The background color for the hover state of the inverse Tertiary button will be updated to align with the inverse Primary and Secondary button`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Button Focus Ring Update`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Focus rings across Primary and Secondary buttons will be more consistent`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Removal of 13px Figma Font Styles`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Previously deprecated 13px Figma font styles will be removed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Tooltip Font Style Update`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tooltip will be updated from using a deprecated 13px font style to a supported font style`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Space & Depth Rem Conversion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space and Depth tokens will be converted from pixel to rem`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of spaceNumbers`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`In favor of moving to rems, `}<inlineCode parentName="td">{`spaceNumbers`}</inlineCode>{` will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Select Compound Component Conversion`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A new Select compound component will replace the current Select component in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of Select in Preview`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Select component in Preview will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode>{` utility will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deprecation of Table in Main`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Table component in Main will be deprecated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
            </tr>
          </tbody>
        </table>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="For Developers" mdxType="TabPanel">
      <UpgradeGuide mdxType="UpgradeGuide" />
    </TabPanel>
    <TabPanel data-id="For Designers" mdxType="TabPanel">
      <h2 {...{
        "id": "getting-started"
      }}>{`Getting Started`}</h2>
      <p>{`This upgrade guide will help designers with the following upgrades`}</p>
      <ol>
        <li parentName="ol">{`Canvas Tokens v1 to Canvas Tokens v2`}</li>
        <li parentName="ol">{`Canvas Web v9 to Canvas Web v10`}</li>
      </ol>
      <p>{`The upgrade guide will help designers:`}</p>
      <ul>
        <li parentName="ul">{`Understand tokens, variables and styles.`}</li>
        <li parentName="ul">{`Decide when to upgrade.`}</li>
        <li parentName="ul">{`Prepare files for an upgrade.`}</li>
        <li parentName="ul">{`Perform the upgrade.`}</li>
        <li parentName="ul">{`Understand the changes in Canvas v10.`}</li>
      </ul>
      <h2 {...{
        "id": "intro-to-variables"
      }}>{`Intro to Variables`}</h2>
      <p>{`Canvas v10 introduces
`}<a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/15339657135383-Guide-to-variables-in-Figma"
        }}>{`Variables`}</a>{` to
Figma libraries. Variables enable the application of tokens in design files, as as well theming
capabilities within Figma.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ef8fcd9051eb115407c95a8ee80a01b5/536c7/upgrade-variables.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Note",
              "title": "Note",
              "src": "/static/ef8fcd9051eb115407c95a8ee80a01b5/50383/upgrade-variables.png",
              "srcSet": ["/static/ef8fcd9051eb115407c95a8ee80a01b5/1efb2/upgrade-variables.png 370w", "/static/ef8fcd9051eb115407c95a8ee80a01b5/50383/upgrade-variables.png 740w", "/static/ef8fcd9051eb115407c95a8ee80a01b5/536c7/upgrade-variables.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Canvas v10 was focused on redefining and implementing a semantic token set shared between design and
engineering.`}</p>
      <h3 {...{
        "id": "design-tokens"
      }}>{`Design Tokens`}</h3>
      <p>{`Design tokens are the most atomic pieces of our Design System with the primary function of storing
UI information. They enable teams to build at scale and set the foundation for theming.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/07d77ce56add6503fcd13bc918f7783c/29007/aliasing.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "45.94594594594595%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7UlEQVR42k1RXWsTQRTd3+GT0BcFq4JUNFZQofGbokUt0opVMFKRVtGoIR+2upumjTUopE9FUB9qtLV+xNKHgBF9UhRFUB/rSkNi2O7szszO7sxOvGtK9XIf7oFzLueeqzT+K8ehtm1TSqWUAAkmlmURQmAuzBZT6VsjufzQSC6hZqNJ9f3Hz8qKEuiVau2XriNkgtiX/sLPBV3XTTOAF2LDbTsPbNt9eN3WjrWbd61a0/ZgalohhJoI+b5g3K8sUZc5QgjP48xzTYIEF83VavZuT2SwNzLYc2ag79ylzuOnnzx7pTgOq9frloWo49YRDayCdRtTGmwJHP2t9Hj+fDQZTWnRpHZtKHP2YmzmxZziMFatVg3DgNua7OUrpOScr8zp2/mBq9evpLTLCTV+Y3TfkRMPH80oIDBN9LtWQzY2sctcDxpkXHATW8yF8oATV7MHu08dPdnf1Rs51te/fW/X1PRzBbJdXKwsGQZwsMMagmKCMaGgwJQ0hIQTYAEEtro1tKF9z5aOzlD4UGsoXHj68l/aXMixt1a8ZBvUD97msTtv7o2XJyl34BOZ3MTE5P3EzbH1ofCmHftbNrYHtpt5ANuXjeIPVvhKiRdA7ov57+W5b6894QGcL5VL5XePZ4ux4UxSgz9rHz59+QPDIqeT6b3McwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of swapping the primary color",
              "title": "Example of swapping the primary color",
              "src": "/static/07d77ce56add6503fcd13bc918f7783c/50383/aliasing.png",
              "srcSet": ["/static/07d77ce56add6503fcd13bc918f7783c/1efb2/aliasing.png 370w", "/static/07d77ce56add6503fcd13bc918f7783c/50383/aliasing.png 740w", "/static/07d77ce56add6503fcd13bc918f7783c/29007/aliasing.png 1600w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`v10 componetns are built with a new token library using Figma variables. Variables enable theming,
enforce consistency in designs, and facilitates collaboration betweeen multidisciplinary teams
through a shared language.`}</p>
      <p>{`Before v10, tokens could only be applied as a
`}<a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/360039238753-Styles-in-Figma"
        }}>{`Figma Style`}</a>{`.`}</p>
      <h3 {...{
        "id": "variables-vs-styles"
      }}>{`Variables vs. Styles`}</h3>
      <p>{`Tokens can be represented as a Style or as a Variable in Figma.`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Styles`}</strong>{` are reusable collections of design properties that can be applied to designs.`}</li>
        <li parentName="ul"><strong parentName="li">{`Variables`}</strong>{` are reusable values that can also be applied to design properties, as well as
prototyping actions.`}</li>
      </ul>
      <h4 {...{
        "id": "how-theyre-the-same"
      }}>{`How They're the Same`}</h4>
      <ul>
        <li parentName="ul">{`Both can serve as a source of truth for tokens`}</li>
        <li parentName="ul">{`Both may be published to team libraries`}</li>
        <li parentName="ul">{`Both may be tied to components in Figma libraries`}</li>
        <li parentName="ul">{`Both can support theming at different levels`}</li>
      </ul>
      <h4 {...{
        "id": "how-theyre-different"
      }}>{`How They're Different`}</h4>
      <ul>
        <li parentName="ul">{`Variables hold one value, styles can hold multiple values`}</li>
        <li parentName="ul">{`Variables may reference other variables; styles cannot be referenced`}</li>
        <li parentName="ul">{`Variables are themed through modes, and styles support theming through library swapping.`}</li>
      </ul>
      <h4 {...{
        "id": "approach-to-tokens"
      }}>{`Approach to Tokens`}</h4>
      <p>{`In Canvas Tokens v2, we use both Styles and Variables to represent design tokens.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Feature`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Variables`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Styles`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Solid colors`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Gradients`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`❌`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Typography`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`❌`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Space`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`❌`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Shape`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`❌`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`❌`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✅`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`To learn more about variables, view
`}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=1ONxxlJnvdM"
        }}>{`Figma's tutorial on intro to variables`}</a>{`.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "changes-in-canvas-web-v10"
      }}>{`Changes in Canvas Web v10`}</h2>
      <h3 {...{
        "id": "whats-new-in-v10"
      }}>{`What's New in V10`}</h3>
      <h4 {...{
        "id": "canvas-tokens-v2"
      }}>{`Canvas Tokens v2`}</h4>
      <p>{`Canvas Styles and variables in this library are synced to a shared token repository.`}</p>
      <p>{`Upgrading from Canvas tokens v1 to Canvas tokens v2 will not result in breaking changes as we are
not changing existing values.`}</p>
      <h3 {...{
        "id": "new-token-structure--naming-convention"
      }}>{`New Token Structure & Naming Convention`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/07d77ce56add6503fcd13bc918f7783c/29007/aliasing.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "45.94594594594595%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7UlEQVR42k1RXWsTQRTd3+GT0BcFq4JUNFZQofGbokUt0opVMFKRVtGoIR+2upumjTUopE9FUB9qtLV+xNKHgBF9UhRFUB/rSkNi2O7szszO7sxOvGtK9XIf7oFzLueeqzT+K8ehtm1TSqWUAAkmlmURQmAuzBZT6VsjufzQSC6hZqNJ9f3Hz8qKEuiVau2XriNkgtiX/sLPBV3XTTOAF2LDbTsPbNt9eN3WjrWbd61a0/ZgalohhJoI+b5g3K8sUZc5QgjP48xzTYIEF83VavZuT2SwNzLYc2ag79ylzuOnnzx7pTgOq9frloWo49YRDayCdRtTGmwJHP2t9Hj+fDQZTWnRpHZtKHP2YmzmxZziMFatVg3DgNua7OUrpOScr8zp2/mBq9evpLTLCTV+Y3TfkRMPH80oIDBN9LtWQzY2sctcDxpkXHATW8yF8oATV7MHu08dPdnf1Rs51te/fW/X1PRzBbJdXKwsGQZwsMMagmKCMaGgwJQ0hIQTYAEEtro1tKF9z5aOzlD4UGsoXHj68l/aXMixt1a8ZBvUD97msTtv7o2XJyl34BOZ3MTE5P3EzbH1ofCmHftbNrYHtpt5ANuXjeIPVvhKiRdA7ov57+W5b6894QGcL5VL5XePZ4ux4UxSgz9rHz59+QPDIqeT6b3McwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Buttons aliased to different colors",
              "title": "Buttons aliased to different colors",
              "src": "/static/07d77ce56add6503fcd13bc918f7783c/50383/aliasing.png",
              "srcSet": ["/static/07d77ce56add6503fcd13bc918f7783c/1efb2/aliasing.png 370w", "/static/07d77ce56add6503fcd13bc918f7783c/50383/aliasing.png 740w", "/static/07d77ce56add6503fcd13bc918f7783c/29007/aliasing.png 1600w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Canvas Tokens have been restructured as a part of Canvas v10 to`}</p>
      <ul>
        <li parentName="ul">{`Enable scalable visual changes at the token level that flow across all Canvas components`}</li>
        <li parentName="ul">{`Increase cohesion across Canvas components by standardizing token usage with semantic tokens`}</li>
        <li parentName="ul">{`Empower product teams to build custom components using semantic tokens that can evolve with Canvas
tokens`}</li>
      </ul>
      <h4 {...{
        "id": "new-space--shape-variables"
      }}>{`New Space & Shape Variables`}</h4>
      <p><a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/15339657135383-Guide-to-variables-in-Figma"
        }}>{`Figma variables`}</a>{`
have been released as a separate token library that Canvas v10 pulls from. These Figma variables
will contain the pre-set values of all Canvas space and shape tokens to help designers quickly
switch between different space and shape tokens without needing to reference documentation or
memorize the token values.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a008f08b9a8175c0e138055f6e8b8d01/536c7/upgrade-dimension.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Space and shape variables in Figma",
              "title": "Space and shape variables in Figma",
              "src": "/static/a008f08b9a8175c0e138055f6e8b8d01/50383/upgrade-dimension.png",
              "srcSet": ["/static/a008f08b9a8175c0e138055f6e8b8d01/1efb2/upgrade-dimension.png 370w", "/static/a008f08b9a8175c0e138055f6e8b8d01/50383/upgrade-dimension.png 740w", "/static/a008f08b9a8175c0e138055f6e8b8d01/536c7/upgrade-dimension.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Variables are only visible when using the associated design property. As such, space variables are
only visible within the Auto Layout window, while shape variables can only be utilized as corner
radiuses in Figma.`}</p>
      <h3 {...{
        "id": "new-color-styles--variables"
      }}>{`New Color Styles & Variables`}</h3>
      <p>{`Components have been updated to point to `}<inlineCode parentName="p">{`brand`}</inlineCode>{` styles backed by variables to match customer
theming implementation.`}</p>
      <p>{`Swapping themes under the Layers panel will now swap the brand colors to another theme, helping
differentiate the parts of UI that are and are not theme-able.`}</p>
      <h3 {...{
        "id": "code-syntax"
      }}>{`Code Syntax`}</h3>
      <p>{`Variables support for CSS, SwiftUI (iOS), and Kotlin (Android).`}</p>
      <p>{`Design tokens are platform agnostic and may be represented in different ways, depending on the
programming language or design tool. Here's an example using the primary brand color.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/667c6c7628a840311a53fbc9d8f58bef/536c7/codesyntax.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "CSS, iOS, and Android tokens represented by code syntax",
              "title": "CSS, iOS, and Android tokens represented by code syntax",
              "src": "/static/667c6c7628a840311a53fbc9d8f58bef/50383/codesyntax.png",
              "srcSet": ["/static/667c6c7628a840311a53fbc9d8f58bef/1efb2/codesyntax.png 370w", "/static/667c6c7628a840311a53fbc9d8f58bef/50383/codesyntax.png 740w", "/static/667c6c7628a840311a53fbc9d8f58bef/536c7/codesyntax.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`All of these are correct for their platform (CSS, SwiftUI, and Kotlin). Prior to v10, Figma was only
able to show the Style name. Now, tokens are represented in the programming language the developer is
working in.`}</p>
      <h3 {...{
        "id": "token-rollout-to-canvas-buttons"
      }}>{`Token Rollout to Canvas Buttons`}</h3>
      <p>{`Primary, Secondary, Tertiary, and Delete Buttons have been updated to use the new system and
brand-level tokens. Elements that customers can brand, such as the text color of Primary Buttons,
are tied to a brand token.`}</p>
      <p>{`This is not a breaking change for designers. Canvas buttons in the Canvas Web v10 Figma library will
be linked to the new system and brand tokens automatically. Since Canvas components have always been
structured to reference receipts, the new token set will also be rolled out across all Canvas
components in the Canvas Web v10 Figma library.`}</p>
      <p>{`Please note that this will temporarily create discrepancies between what is available in the code.
Canvas components must be refactored in code before they can uptake the new token set.`}</p>
      <h2 {...{
        "id": "whats-changed"
      }}>{`What's Changed`}</h2>
      <h3 {...{
        "id": "tertiary-inverse-button-styling-standardization"
      }}>{`Tertiary Inverse Button Styling Standardization`}</h3>
      <p>{`The inverse variant of Tertiary Buttons will be updated to align with the styling of the inverse
variants of the Primary and Secondary buttons using the new token set.`}</p>
      <p>{`By connecting the inverse variant of Tertiary Buttons to the same set of tokens used by Primary and
Secondary Buttons, the background color for the hover state of the inverse Tertiary Buttons will be
updated from French Vanilla 100 to Soap 300.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5f8adcb4d5484906930ea7ce220fc831/536c7/tertiary.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Before and after tertiary button updates",
              "title": "Before and after tertiary button updates",
              "src": "/static/5f8adcb4d5484906930ea7ce220fc831/50383/tertiary.png",
              "srcSet": ["/static/5f8adcb4d5484906930ea7ce220fc831/1efb2/tertiary.png 370w", "/static/5f8adcb4d5484906930ea7ce220fc831/50383/tertiary.png 740w", "/static/5f8adcb4d5484906930ea7ce220fc831/536c7/tertiary.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`The new background color will be applied automatically once designers upgrade to Canvas v10 and will
not result in any breaking changes. This update will also be reflected in code, but no action will
be required from developers to uptake this change.`}</p>
      <h3 {...{
        "id": "color-styles-backed-by-variables"
      }}>{`Color Styles Backed by Variables`}</h3>
      <p>{`All styles now point to a variable instead of a hard-coded value to make the transition from Styles
to Variables more seamless.`}</p>
      <h3 {...{
        "id": "using-tokens"
      }}>{`Using Tokens`}</h3>
      <p>{`The Canvas Tokens v2 library is integrated into Canvas Web v10 Figma library, enabling brand theming and more seamless
collaboration with engineering partners.`}</p>
      <h3 {...{
        "id": "whats-changed-1"
      }}>{`What's Changed`}</h3>
      <h4 {...{
        "id": "backed-color-styles-with-variables"
      }}>{`Backed Color Styles with Variables`}</h4>
      <p>{`Color styles are now backed by variables for dynamic theming and mode switching. Color variables are
hidden by default for a cleaner workspace. Since color variables support all color styles,
mode-switching capabilities still work as intended.`}</p>
      <h4 {...{
        "id": "buttons-foreground-and-background-colors"
      }}>{`Buttons Foreground and Background Colors`}</h4>
      <p>{`Fixed a bug in Primary, Secondary, Tertiary, and Delete Buttons. Foregrounds & backgrounds now refer
to the same color style to ensure theming in buttons works as intended. This update will not result
in any breaking changes.`}</p>
      <h4 {...{
        "id": "checkbox-and-radio-accent-color"
      }}>{`Checkbox and Radio Accent Color`}</h4>
      <p>{`Selected Checkbox and Radio accents have been updated from base French Vanilla 100 to 
Primary/French Vanilla 100 to ensure a smooth transition between v9 to v10.`}</p>
      <p>{`This update will not result in any breaking changes.`}</p>
      <h4 {...{
        "id": "deprecated-small-13px-is-deprecated-in-v5-text-styles"
      }}>{`Deprecated ‘🚫Small `}{`[13px is deprecated in V.5]`}{`’ Text Styles`}</h4>
      <p>{`Previously deprecated 13px type styles have been removed in the Canvas Tokens v2 Figma library.
These font sizes were deprecated in Canvas v5. They'll still be available for designers through the
Canvas Tokens V1 library. However, they will not be supported in the Canvas Tokens v2 Figma library.`}</p>
      <p>{`When designers swap over to the Canvas Tokens v2 Figma library, type styles using 13px font size
will no longer be available in the Figma styles menu. However, all other types of styles will still
be supported in the new Canvas Tokens v2 Figma library. Designs using the deprecated 13px text
styles will persist after the library swap. Still, it is recommended for designers to switch over to
using a supported text style to align with other Workday products.`}</p>
      <h4 {...{
        "id": "tooltip-text"
      }}>{`Tooltip Text`}</h4>
      <p>{`Tooltip has been updated in Figma to use `}<inlineCode parentName="p">{`Subtext M - (400) Regular`}</inlineCode>{` instead of the deprecated 13px
text styles to align with what's in React.`}</p>
      <p>{`This does not impact the `}<inlineCode parentName="p">{`lineHeight`}</inlineCode>{`, but will decrease the overall width of the tooltip as
`}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` decreases to 0.75 rem. Top and bottom padding will also increase from 0.375 to .5 rems (6
going to 8px) to maintain tooltip height.`}</p>
      <p>{`This update will result in a visual change for designers, but the update will be applied
automatically once designers upgrade to v10.`}</p>
      <h2 {...{
        "id": "getting-started-with-v10"
      }}>{`Getting Started with V10`}</h2>
      <h3 {...{
        "id": "before-upgrading"
      }}>{`Before Upgrading`}</h3>
      <p>{`Upgrading uses
`}<a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/4404856784663-Swap-style-and-component-libraries"
        }}>{`Figma's Swap Library feature`}</a>{`
and is a manual process.`}</p>
      <h3 {...{
        "id": "deciding-to-upgrade"
      }}>{`Deciding to Upgrade`}</h3>
      <p>{`Coordinating the upgrade as a team is crucial. This ensures that designs align with what's
technically feasible based on the version of Canvas developer partners use. We recommend discussing
with your team to synchronize your upgrade schedules.`}</p>
      <h3 {...{
        "id": "prepare-for-the-upgrade"
      }}>{`Prepare for the Upgrade`}</h3>
      <h4 {...{
        "id": "update-the-current-version"
      }}>{`Update the Current Version`}</h4>
      <p>{`Before upgrading to Canvas Web v10, make sure to accept any changes in Canvas Web v9. While there shouldn't be any
breaking changes in v10, this is a precautionary action to prevent unforeseen changes.`}</p>
      <h4 {...{
        "id": "identify-key-files-for-the-upgrade"
      }}>{`Identify Key Files for the Upgrade`}</h4>
      <p>{`Mark the covers of the files that should be upgraded to Canvas Web v10. This helps your team identify which
files are actively being maintained and which should be archived.`}</p>
      <h3 {...{
        "id": "back-up-key-files"
      }}>{`Back Up Key Files`}</h3>
      <p>{`Before upgrading, consider backing up your current version through a branch.`}</p>
      <h4 {...{
        "id": "create-a-branch-of-the-main-file-recommended"
      }}>{`Create a Branch of the Main File (Recommended)`}</h4>
      <p>{`Branching saves a version of your current file and creates a separate space to upgrade. Merging back
to main will show the before and after, as well as any diffs.`}</p>
      <h4 {...{
        "id": "duplicate-key-files"
      }}>{`Duplicate Key Files`}</h4>
      <p>{`Alternatively, you can duplicate essential files and upgrade those, indicating the Canvas version
for clarity.`}</p>
      <h3 {...{
        "id": "making-the-upgrade"
      }}>{`Making the Upgrade`}</h3>
      <p>{`To make the upgrade, you'll first want to make sure you resolve any updates and are using the
correct tokens library.`}</p>
      <ol>
        <li parentName="ol">{`Open the file. If there are updates to Canvas Web v9, review and accept those updates.`}</li>
        <li parentName="ol">{`(Optional) Create a branch or duplicate file to apply the upgrade.`}</li>
      </ol>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e0b70e9cc964784775c8b65303593d91/536c7/branches.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Create branch",
              "title": "Create branch",
              "src": "/static/e0b70e9cc964784775c8b65303593d91/50383/branches.png",
              "srcSet": ["/static/e0b70e9cc964784775c8b65303593d91/1efb2/branches.png 370w", "/static/e0b70e9cc964784775c8b65303593d91/50383/branches.png 740w", "/static/e0b70e9cc964784775c8b65303593d91/536c7/branches.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol {...{
        "start": 3
      }}>
        <li parentName="ol">{`Go to the Assets Panel and open Team Libraries.`}</li>
      </ol>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c263558d04a9b5d37842c3564b4ccc13/536c7/team-libs.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "team libraries",
              "title": "team libraries",
              "src": "/static/c263558d04a9b5d37842c3564b4ccc13/50383/team-libs.png",
              "srcSet": ["/static/c263558d04a9b5d37842c3564b4ccc13/1efb2/team-libs.png 370w", "/static/c263558d04a9b5d37842c3564b4ccc13/50383/team-libs.png 740w", "/static/c263558d04a9b5d37842c3564b4ccc13/536c7/team-libs.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol {...{
        "start": 4
      }}>
        <li parentName="ol">{`Select Canvas Tokens and Select Swap Library`}</li>
      </ol>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2a0bbe351f3c58cfd4d0d8284ea6bc50/536c7/swap-libs.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Swap libraries",
              "title": "Swap libraries",
              "src": "/static/2a0bbe351f3c58cfd4d0d8284ea6bc50/50383/swap-libs.png",
              "srcSet": ["/static/2a0bbe351f3c58cfd4d0d8284ea6bc50/1efb2/swap-libs.png 370w", "/static/2a0bbe351f3c58cfd4d0d8284ea6bc50/50383/swap-libs.png 740w", "/static/2a0bbe351f3c58cfd4d0d8284ea6bc50/536c7/swap-libs.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol {...{
        "start": 5
      }}>
        <li parentName="ol">{`Swap to Canvas Tokens v2.`}</li>
        <li parentName="ol">{`Wait for confirmation of library swap.`}</li>
        <li parentName="ol">{`Open the Libraries Panel again and enable Canvas Tokens v2 and disable Canvas Tokens v1.`}</li>
      </ol>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/67641db6f5a4b29726e5cacaf0cdf867/536c7/turnonlibs.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Turning on the correct libraries",
              "title": "Turning on the correct libraries",
              "src": "/static/67641db6f5a4b29726e5cacaf0cdf867/50383/turnonlibs.png",
              "srcSet": ["/static/67641db6f5a4b29726e5cacaf0cdf867/1efb2/turnonlibs.png 370w", "/static/67641db6f5a4b29726e5cacaf0cdf867/50383/turnonlibs.png 740w", "/static/67641db6f5a4b29726e5cacaf0cdf867/536c7/turnonlibs.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "canvas-web-v10"
      }}>{`Canvas Web v10`}</h3>
      <ol>
        <li parentName="ol">{`If there are any updates from Canvas Web v9, go ahead and make those updates.`}</li>
        <li parentName="ol">{`Go to the Assets panel and open Team Libraries.`}</li>
        <li parentName="ol">{`Select the current library used in your file, Canvas Web v9`}</li>
        <li parentName="ol">{`Choose the 'Swap Library' option.`}</li>
        <li parentName="ol">{`Select the Canvas Web v10 library for the upgrade.`}</li>
        <li parentName="ol">{`Confirm the 'Swap Library' action.`}</li>
        <li parentName="ol">{`Wait for Figma to confirm a successful library swap.`}</li>
      </ol>
      <p>{`Any Canvas Web v9 component in your file should have been swapped to the latest Canvas Web v10 library. To
test this, try changing the Brand dropdown located in the Page section on the design panel.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c263558d04a9b5d37842c3564b4ccc13/536c7/team-libs.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Tokens in vscode",
              "title": "Tokens in vscode",
              "src": "/static/c263558d04a9b5d37842c3564b4ccc13/50383/team-libs.png",
              "srcSet": ["/static/c263558d04a9b5d37842c3564b4ccc13/1efb2/team-libs.png 370w", "/static/c263558d04a9b5d37842c3564b4ccc13/50383/team-libs.png 740w", "/static/c263558d04a9b5d37842c3564b4ccc13/536c7/team-libs.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`You should see the brand theme swap for every frame on the page.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/21310247930743b9ca698f79b89b9cae/536c7/swap-themes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Swap local themes",
              "title": "Swap local themes",
              "src": "/static/21310247930743b9ca698f79b89b9cae/50383/swap-themes.png",
              "srcSet": ["/static/21310247930743b9ca698f79b89b9cae/1efb2/swap-themes.png 370w", "/static/21310247930743b9ca698f79b89b9cae/50383/swap-themes.png 740w", "/static/21310247930743b9ca698f79b89b9cae/536c7/swap-themes.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`If branching is used, Merging the branch back into the main should show you which screens were
impacted by the change.`}</p>
      <p>{`Lastly, the new library should be set to persist in your team's files. To do this, your team's admin
must complete the following steps.`}</p>
      <ol>
        <li parentName="ol">{`Click on your Team in Figma.`}</li>
        <li parentName="ol">{`Go to the Settings tab.`}</li>
        <li parentName="ol">{`Select 'Enable Libraries for all team files.'`}</li>
        <li parentName="ol">{`Turn off the old library.`}</li>
        <li parentName="ol">{`Activate the new library.`}</li>
      </ol>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      